import React from 'react'
import { Reveal, Tween } from 'react-gsap'
import { GridContainer } from '../../../styles/grid/grid'

const GridContainerAnimated = ({ children }) => {
    return (
        <Reveal threshold={0.3}>
            <Tween
                from={{ y: 50, opacity: 0 }}
                to={{ y: 0, opacity: 1 }}
                duration={0.3}
            >
                <GridContainer>
                    {children}
                </GridContainer>
            </Tween>
        </Reveal>
    )
}

export default GridContainerAnimated
import styled from "styled-components";
import ErrorSVG from '/public/svg/error.svg';

export const FormError = ({ errorText }) => {
    return (
        <FormErrorContainer>
            <ErrorSVG />
            {errorText}
        </FormErrorContainer>
    )
}

export const FormErrorContainer = styled.span`
    margin-top: 9px;
    margin-bottom: 0px;
    color: ${(props) => props.theme.colors.error};
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;

    svg {
        height: 16px;
        width: 16px;
        margin-right: 4px;
        &>* {
            fill: ${(props) => props.theme.colors.error};
        }
    }
`